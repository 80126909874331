import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const privacy = ({ data }) => {
  return (
    <Layout language="nl" en="privacy" nl="privacy">
      <SEO title="Privacy" keywords={[`valta`, `lamp`, `licht`, `privacy`]} />
      <div className="articleHero gridFullWidth">
        <h1>Privacy</h1>
      </div>
      <article className="gridFullWidth">
        <div className="articleContent">
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </div>
        <aside>
          <div className="scrollingMenu"></div>
        </aside>
      </article>
    </Layout>
  )
}

export const query = graphql`
  {
    mdx(frontmatter: { slug: { eq: "privacy" } }) {
      frontmatter {
        slug
        title
        subtitle
        description
      }
      body
    }
  }
`

export default privacy
